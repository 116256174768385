import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
// import TreeIcon from '@mui/icons-material/AccountTree';
import ViewInArIcon from '@mui/icons-material/ViewInAr';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'dashboard',
    path: '/',
    icon: <HomeIcon />,
    roles: ['admin', 'framework_creator', 'verifier', 'task_assignee'], // Roles that can access this item
  },
  {
    title: 'create framework',
    path: '/template/create',
    icon: <ViewInArIcon />,
    roles: ['admin', 'framework_creator'], // Only admin can access this
  },
  {
    title: 'user',
    path: '/user',
    icon: <PersonIcon />,
    roles: ['admin'], // Only admin can access this
  },
  // {
  //   title: 'framework tree',
  //   path: '/framework-tree',
  //   icon: <TreeIcon />, // Use the imported icon for the tree view
  //   roles: ['admin', 'user'], // Admin and user can access this
  // },
];

export default navConfig;
